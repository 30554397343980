<template lang="pug">
    div.waiting-wall
        loader.loader
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    components: {
        Loader: () => import ("skillbase-component/src/components/common/UploadLoader.vue")
    }
})
</script>

<style lang="scss" scoped>
    .loader {
        margin-top: calc(50vh - 30px);
        margin-left: calc(50vw - 25px);
    }
</style>